<template>
  <div class="contain">
    <div class="main">
      <!-- <div class="logoBox" @click="goHome"> -->
      <div class="logoBox">
        <!-- <div class="logoMain"> -->
        <el-image :src="logo"></el-image>
        <!-- </div> -->
        <!-- <div class="logoTxt">
          <div> 瀚维科技 </div>
          <div> Hanwei·Tech </div>
        </div> -->
        <!-- </div> -->
        <!-- <div class="logoName">
          <h3>{{ $t('titles.home') }}</h3>
        </div> -->
      </div>
      <div class="title">
        <ul class="list">
          <li v-for="(item, index) in titleList" :key="index">
            <!-- <h3 @click="jumppage(index)" :class="{ active: chooseIndex == index }">{{ $t(item) }}</h3> -->
            <div @click="jumppage(index)" :class="{ active: chooseIndex == index }">{{ $t(item) }}</div>
            <div v-show="(chooseIndex === index ? true : false)" class="borderBottom"></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "headers",
  data() {
    return {
      // titleList: ['titles.home', "titles.service", "titles.datacenter",  'titles.about', 'titles.contactUs', "titles.lange"],
      titleList: ['titles.home', "titles.service", "titles.datacenter", 'titles.about'],
      chooseIndex: 0,
      logo: require("./images/logo.png")
    };
  },
  mounted() {
    let path = this.$route.path
    let that = this
    this.switchPath(path)
    this.$bus.$on('setRoute', (data) => {
      that.switchPath(data)
    })
  },

  // 销毁之前
  beforeDestroy() {
    this.$bus.off('setRoute')
  },

  methods: {

    switchPath(path) {
      switch (path) {
        case '/home':
          this.chooseIndex = 0
          break;
        case '/service':
          this.chooseIndex = 1
          break;
        case '/datacenter':
          this.chooseIndex = 2
          break;
        case '/about':
          this.chooseIndex = 3
          break;
        default:
          break;
      }
    },

    newAddBtn() {
      let me = this;
      this.$nextTick(function () {
        me.inputStyWidth = me.$refs.companyStyle.$el.clientWidth + 'px';
      })
    },
    // goHome() {
    //   this.$router.push("/home");
    //   this.chooseIndex = 0
    // },
    jumppage(val) {
      if (val < 4) {
        this.chooseIndex = val
      }

      switch (val) {
        case 0:
          this.$router.push("/home");
          break;
        case 1:
          this.$router.push("/service");
          break;
        case 2:
          this.$router.push("/datacenter");
          break;
        case 3:
          this.$router.push("/about");
          break;
        // case 4:
        //   this.handleLanguage();
        //   break;

        default:
          break;
      }
    },

    // 中英文切换
    handleLanguage() {
      this.$i18n.locale = this.$i18n.locale === 'zh' ? 'en' : 'zh'
    },

  },
};
</script>

<style lang="less" scoped>
.contain {
  background: #000032;
  position: fixed;
  z-index: 1000;
  width: 100%;

  .main {
    display: flex;
    height: 80px;
    width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
    background: #000032;

    .logoBox {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 211px;
      height: 80px;
      // cursor: pointer;

      // .logoMain{
      //   width: 43px;
      //   height: 45px;
      .img {
        height: 100%;
        width: 100%;
      }

      // }

      .logoTxt {
        font-size: 16px;
        color: #fff;
        margin-left: 10px;
      }

      .logoName {
        margin-left: 20px;
        width: 240px;
      }
    }

    .logoBox:hover {
      color: blue;
    }

    .title {
      width: 424px;

      ul {
        display: flex;
        align-items: center;
        line-height: 80px;
        justify-content: space-around;

        li {
          cursor: pointer;
          color: #fff;
          overflow: hidden;
          position: relative;
          font-size: 18px;

          .borderBottom {
            width: 100%;
            height: 6px;
            background: #00acf6;
            position: absolute;
            bottom: 0;
            left: 0;
          }
        }

        li:hover {
          color: #00acf6;
          contain: '12312';
        }
      }
    }
  }
}

.active {
  color: #3bc7fc;
}
</style>