<template>
  <div class="footer">
    <div class="footer-container">
      <div class="footerList">
        <div class="footerItem" v-for="(item, index ) in titleList" :key="index">
          <h4 class="h4" @click="jumppage(index)">{{ $t(item.name) }}</h4>
          <!-- <ul class="footerItemCon" :class="[index === 2 ? 'addWidth' : '']"> -->
          <ul class="footerItemCon">
            <div v-for="(items, indexs) in item.content" :key="indexs">
              <!-- <li @click="changNav(items, item.name)" :class="[index == 2 && indexs < 2 ? 'liAdd':'']"> {{ items }} </li> -->
              <li @click="changNav(items, item.name)"> {{ items }} </li>
            </div>
          </ul>
        </div>
      </div>

      <div class="rightBox">
        <div class="rightMain">
          <h4 class="h4">联系我们</h4>
          <ul class="footerItemCon">
            <li> 地址：北京市经济技术开发区地盛北街1号院31-2 </li>
            <li> 电话：010-87227768 </li>
            <li> 邮箱：hanwei@rfhw.cn </li>
          </ul>
        </div>
      </div>

      <div class="iconTop" @click="topClick">
        <i class="el-icon-download"></i>
      </div>

    </div>
    <div class="copyright">
      <p> <a href="https://beian.miit.gov.cn">COPYRIGHT © 2022 北京瑞丰瀚维科技发展有限公司. All Rights Reserved
          京ICP备2022018034号-1</a>
      </p>
    </div>

    <el-dialog title="温馨提示" :visible.sync="centerDialogVisible" width="30%" center>
      <!-- <span class="tipCenter"> 建议使用 1024*786分辨率以上来浏览 </span> -->
      <div class="tipCenter"> 建议使用 1024*786分辨率以上来浏览 </div>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="centerDialogVisible = false">取 消</el-button> -->
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
var VueScrollTo = require("vue-scrollto");
export default {
  name: "Footers",
  data() {
    return {
      titleList: [
        { name: 'titles.home', content: ['走进瀚维', '我们的优势', '我们的服务'] },
        { name: 'titles.service', content: ['机房运维服务', '规划咨询设计', '信息技术服务'] },
        // { name: "titles.datacenter", content: ['机房基本信息', '机房电力情况', '不间断电源系统', '数据机房情况', '空调与制冷系统', '消防设施', '动环监控系统', '网络传输资源'] },
        { name: "titles.datacenter", content: ['北京铁通IDC-T3数据中心'] },
        { name: 'titles.about', content: ['企业介绍', '发展历程', '联系我们', '加入我们'] }
      ],

      screenWidth: document.documentElement.clientWidth,//屏幕宽度
      centerDialogVisible:  false,
    }
  },

  // <!--在watch中监听实时宽高-->
  watch: {
    screenWidth: function (val) {
      console.log('width', val)
      if(val < 1024){
        this.centerDialogVisible = true
      }else{
        this.centerDialogVisible = false
      }
    }
  },

  mounted() {
    let that = this
    window.addEventListener('resize', function () {
      that.screenWidth = document.body.offsetWidth;
    })
  },

  methods: {
    jumppage(val) {
      switch (val) {
        case 0:
          this.$router.push("/home");
          break;
        case 1:
          this.$router.push("/service");
          break;
        case 2:
          this.$router.push("/datacenter");
          break;
        case 3:
          this.$router.push("/about");
          break;
        case 4:
          this.handleLanguage();
          break;
        default:
          break;
      }
    },

    // 导航选中效果
    changNav(val, name) {
      if(val === '加入我们'){
        return
      }
      let path = "/" + name.slice(7)
      if (path === '/datacenter') {
        val = '数据中心'
      }
      // this.$router.push(path);
      this.$router.push({ path: path, query: { val } });
      this.$bus.$emit('setRoute', path)
      setTimeout(() => {
        VueScrollTo.scrollTo(document.getElementById(val), 1000, {
          offset: -50,
        });
      }, 100)
    },

    // 回到顶部
    topClick() {
      VueScrollTo.scrollTo({ offset: 0 });
    }
  }
};
</script>

<style lang="less" scoped>
.footer {
  background-color: #001C41;

  .footer-container {
    width: 1200px;
    margin: 0 auto;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    justify-content: space-between;
    position: relative;
    background-color: #001C41;

    .footerList {
      // width: 656px;
      width: 690px;
      display: flex;
      justify-content: space-between;
      border-right: 1px solid #707784;
      // padding-right: 114px;
      padding-right: 80px;

      .footerItem {
        color: #707784;

        h4 {
          font-size: 16px;
          color: #fff;
          margin-bottom: 26px;
        }

        .footerItemCon {
          display: block;

          .liAdd {
            margin: 0;
          }

          li {
            line-height: 26px;
            margin-top: 12px;
            font-size: 16px;
            cursor: pointer;
          }
        }

        &:last-child img {
          width: 121px;
        }

        .addWidth {
          width: 180px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }
    }

    .rightBox {
      color: #fff;
      padding-left: 40px;
      // padding-left: 114px;

      .rightMain {
        h4 {
          font-size: 16px;
          color: #fff;
          margin-bottom: 26px;
        }

        .footerItemCon {
          display: block;

          li {
            line-height: 26px;
            font-size: 16px;
            margin-top: 12px;
            color: #707784;
          }
        }
      }
    }

    .iconTop {
      position: absolute;
      right: 0;
      bottom: 20px;
      background: skyblue;
      color: #fff;
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: rotate(180deg);

      .el-icon-download {
        font-size: 30px;
      }
    }

  }

  .copyright {
    font-size: 16px;
    height: 68px;
    line-height: 68px;
    background: #000032;
    min-width: 1200px;

    p {
      text-align: center;
    }
  }

  .tipCenter{
    text-align: center;
  }
}
</style>