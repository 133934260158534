
<template>
  <div class="contain">

    <div class="aboutBox" v-show="!recruitShow">
      <div class="imgBox">
        <!-- <div class="aboutAdd">关于我们</div> -->
        <el-image class="img" :src="img"></el-image>
      </div>

      <div class="introduce">

        <div class="introduceMain" id="企业介绍">

          <div class="img01">
            <div class="border"></div>
            <el-image class="img" :src="img01"></el-image>
          </div>
          <div class="introduceInner">
            <div class="introduceTitle"> 北京瑞丰瀚维科技发展有限公司 </div>
            <div class="introduceTxt">
              <p class="p">
                瀚维科技是一家专业从事数据中心规划、建设、运营、服务为一体的高技术企业，业务范围涵盖数据中心规划咨询与设计、建设工程项目管理、电信服务运营管理、数据中心运维管理、应用软件开发、信息技术服务等。
                公司自成立以来即与中移铁通深度合作，充分借力电信运营商的基础优势和专业化建设经验，通过科学的管理及灵活的操作，实现数据中心的快速部署。北京铁通IDC-T3数据中心顺利竣工并稳定投产标志着瀚维科技作为民营企业与电信运营商深度合作的数据中心建设、运营新模式得到了市场的验证。
              </p>
              <p class="p">
                机房建成后，瀚维科技依托数据中心基础产业优势，进一步向软件开发和系统集成业务方向延伸。旗下技术开发团队和信息化运营服务团队，经过逐年的技术沉淀逐步成为市属国有企业的信息化服务供应商。
              </p>
              <p class="p">
                在国家双碳战略的指引下，瀚维科技积极引进海外先进产品与技术，率先在自有机房部署测试蒸发冷却节能设施，总结经验、改进更新。未来瀚维科技将继续扩大研发投入，进一步探索数据中心节能降耗解决方案。
              </p>
              <p class="p">
                经过多年的探索和打磨，瀚维科技逐步形成了精干、高效、稳健、专业的办事风格，力争在第四次工业革命的信息浪潮中行稳致远。
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="course" id="发展历程">
        <div class="courseTop">
          <el-image class='img' :src="iconBlue"></el-image>
          <div class="courseTitle"> 发展历程 </div>
        </div>
        <div class="courseMain">

          <div class="courseItem" v-for="item in courseArr" :key="item.id">
            <div class="courseImg" v-if="(item.id % 2 == 1 ? true : false)">
              <el-image class="img" :src="item.img"></el-image>
            </div>
            <div class="courseTxt" :class="{ courseActive: item.id % 2 == 0 }" v-if="(item.id % 2 == 1 ? false : true)">
              <p class="p"> {{ item.text }} </p>
            </div>
            <div class="courseIcon">
              <div class="courseYear">{{ item.year }}</div>
              <el-image class="img" :src="item.icon"></el-image>
            </div>
            <div class="courseTxt" v-if="(item.id % 2 == 1 ? true : false)">
              <p class="p"> {{ item.text }} </p>
            </div>
            <div class="courseImg" v-if="(item.id % 2 == 1 ? false : true)">
              <el-image class="img" :src="item.img"></el-image>
            </div>
          </div>

        </div>
      </div>

      <div class="brief" id="联系我们">
        <div class="briefTop">
          <el-image :src="iconBlue"></el-image>
          <div class="briefTitle"> 联系我们 </div>
        </div>
        <div class="mapBox">
          <div class="mapMain">
            <div class="mapInner">
              <div class="mapTop">
                <div :class="[active === 1 ? 'locatTop' : 'locatDown', 'triangle']"></div>
                <div :class="[active === 1 ? 'topItemActive' : '', 'topItem']"
                  @click="tabClick(39.795178, 116.522315, 1)">
                  北京铁通IDC-T3数据中心 </div>
                <div :class="[active === 2 ? 'topItemActive' : '', 'topItem']"
                  @click="tabClick(39.784692, 116.497521, 2)">
                  技术服务中心 </div>
              </div>

              <div class="mapDown">
                <div class="downItem"> <i class="el-icon-location" style="font-size:24px; margin-right: 12px;"></i>
                  {{ active === 1 ? '北京经济技术开发区兴盛街 15 号' : '北京经济技术开发区地盛北街1号院31-2号楼5层' }} </div>
                <div class="downItem"> <i class="el-icon-phone" style="font-size:24px; margin-right: 12px;"></i>
                  010-87227768 </div>
                <div class="downItem"> <i class="el-icon-message" style="font-size:24px; margin-right: 12px;"></i>
                  hanwei@rfhw.cn </div>
              </div>
            </div>
          </div>
          <div id="container" style="width:100%;height:100%;position:relative">
            <i class="el-icon-s-help" @click="locationClick"
              style="fontSize:30px;color:#113f7d;zIndex:999;position: absolute;top: 50px;right: 30px;cursor: pointer;"></i>
          </div>
        </div>
      </div>

      <div class="join" id="加入我们">
        <div class="joinBox">
          <!-- <el-image class="img" :src="joinUs" @click="recruitClick"></el-image> -->
          <el-image class="img" :src="joinUs"></el-image>
        </div>
      </div>
    </div>

    <div class="addBox" v-show="recruitShow" id="加入我们Show">
      <div class="imgBox">
        <el-image class="img" :src="imgAdd"></el-image>
      </div>

      <div class="addMain">
        <div class="backBox" @click="backClick">
          <el-image class="img" :src="back"></el-image>
          <div class="backTxt"> 关于我们 </div>
        </div>

        <div class="addTitle"> 招聘信息 </div>

        <div class="addItem" v-for="item in postArr" :key="item.id">
          <i v-show="item.open" class="el-icon-arrow-up upIcon" @click="openClick('up', item.id)"></i>
          <i v-show="!item.open" class="el-icon-arrow-down downIcon" @click="openClick('down', item.id)"></i>
          <div class="itemName"> {{ item.name }} </div>
          <div class="itemInner" v-show="item.open">
            <p class="p"> {{ item.type }} </p>
            <p class="p"> {{ item.address }} </p>
            <p class="p"> {{ item.introduce }} </p>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
var VueScrollTo = require("vue-scrollto");
export default {
  name: "About",
  data() {
    return {
      back: require("./images/back.png"),
      recruitShow: false,
      iconBlue: require("./images/iconBlue.png"),
      img: require("./images/about01.jpg"),
      img01: require("./images/workshop.jpg"),
      imgAdd: require("./images/aboutAdd.png"),
      joinUs: require("./images/joinUs.jpg"),
      active: 1,
      longitude: '116.522315', // 经度
      latitude: '39.795178', // 纬度
      courseArr: [
        { img: require("./images/course01.jpg"), icon: require("./images/courseIcon01.png"), year: '2014', text: '2014年底，经北京经济技术开发区管委会批准备案，公司与中移铁通深度合作，在北京经济技术开发区兴盛街15号投资建设节能型云计算数据中心。', id: '01', },
        { img: require("./images/course02.jpg"), icon: require("./images/courseIcon02.png"), year: '2015', text: '2015年7月，北京铁通IDC-T3数据中心顺利竣工投产，并持续稳定运营至今。', id: '02', },
        { img: require("./images/course03.jpg"), icon: require("./images/courseIcon01.png"), year: '2017', text: '2017年中旬，公司依托数据中心基础产业优势，完成了软件开发和系统集成业务基础架构的搭建，向信息技术服务产业进军。', id: '03', },
        { img: require("./images/course04.jpg"), icon: require("./images/courseIcon02.png"), year: '2019', text: '2019年6月，公司引进了源自荷兰Oxycom的蒸发冷却产品，进一步探索数据中心节能降耗解决方案。', id: '04', },
      ],

      postArr: [
        { open: true, name: '岗位名称（开发工程师）', type: '岗位类型：互联网、IDC机房', address: '工作地点：北京经济开发区兴盛街15号', introduce: '岗位介绍：岗位内容岗位内容岗位内容岗位内容岗位内容岗位内容岗位内容', id: '1' },
        { open: false, name: '岗位名称（开发工程师）', type: '岗位类型：互联网、IDC机房', address: '工作地点：北京经济开发区兴盛街15号', introduce: '岗位介绍：岗位内容岗位内容岗位内容岗位内容岗位内容岗位内容岗位内容', id: '2' },
        { open: false, name: '岗位名称（开发工程师）', type: '岗位类型：互联网、IDC机房', address: '工作地点：北京经济开发区兴盛街15号', introduce: '岗位介绍：岗位内容岗位内容岗位内容岗位内容岗位内容岗位内容岗位内容', id: '3' },
        { open: false, name: '岗位名称（开发工程师）', type: '岗位类型：互联网、IDC机房', address: '工作地点：北京经济开发区兴盛街15号', introduce: '岗位介绍：岗位内容岗位内容岗位内容岗位内容岗位内容岗位内容岗位内容', id: '4' },
      ],
    }
  },

  mounted() {
    let val = this.$route.query.val
    console.log('val', val)
    this.init(39.795178, 116.522315);
    if (val === '加入我们') {
      this.recruitShow = true
    }
  },

  watch: {
    $route(newVal, oldVal) {
      console.log('newVal', newVal)
      console.log('oldVal', oldVal)
      if (newVal.query.val != "加入我们") {
        this.recruitShow = false
      } else {
        this.recruitShow = true
      }
    },
  },

  methods: {

    // 初始定位
    init(val, val2) {
      // var center = new qq.maps.LatLng(39.79488, 116.52242); // 39.79517896441373 116.52231574058533
      var center = new qq.maps.LatLng(val, val2); // 39.79517896441373 116.52231574058533
      var map = new qq.maps.Map(document.getElementById('container'), {
        center: center,
        zoom: 14
      });

      var anchor = new qq.maps.Point(6, 6),
        size = new qq.maps.Size(24, 24),
        origin = new qq.maps.Point(0, 0),
        icon = new qq.maps.MarkerImage('https://mapapi.qq.com/web/lbs/javascriptV2/demo/img/center.gif', size, origin, anchor);
      var marker = new qq.maps.Marker({
        icon: icon,
        map: map,
        position: map.getCenter()
      });
    },

    // 回到定位点
    locationClick() {
      this.tabClick(this.latitude, this.longitude, this.active)
    },

    tabClick(val, val2, val3) {
      this.init(val, val2)
      this.latitude = val
      this.longitude = val2
      this.active = val3
    },

    // 招聘信息
    recruitClick() {
      this.recruitShow = true
      this.$router.push({ path: '/about', query: { val: '加入我们' } });
      setTimeout(() => {
        VueScrollTo.scrollTo(document.getElementById('加入我们Show'), 1000, {
          offset: -50,
        });
      }, 100)
    },

    // 展开岗位信息
    openClick(val, id) {
      this.postArr.map((item) => {
        if (item.id == id) {
          item.open = true
        } else {
          item.open = false
        }
      })
    },

    backClick() {
      this.recruitShow = false
    }
  }
};
</script>

<style lang='less' scoped>
.aboutBox {
  .imgBox {
    width: 100%;
    // min-width: 1200px;
    height: 610px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .aboutAdd {
      font-size: 64px;
      color: #fff;
      position: absolute;
      z-index: 10;
    }

    .img {
      width: 100%;
      height: 100%;
    }
  }

  .introduce {

    .introduceMain {
      width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      padding: 131px 0;

      .img01 {
        width: 503px;
        height: 283px;
        position: relative;

        .border {
          position: absolute;
          top: -50px;
          left: -360px;
          border: 3px solid #113F7D;
          width: 639px;
          height: 385px;
        }

        .img {
          width: 100%;
          height: 100%;
        }
      }

      .introduceInner {
        width: 686px;
        margin-left: 11px;
        line-height: 30px;

        .introduceTitle {
          font-size: 18px;
          color: #000;
          font-weight: 600;
        }

        .introduceTxt {
          font-size: 16px;
          color: #000;

          .p {
            margin-top: 10px;
            text-indent: 2em;
          }
        }
      }
    }
  }

  .course {
    width: 1200px;
    margin: 0 auto 80px;
    line-height: 30px;

    .courseTop {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      .courseTitle {
        font-size: 24px;
        color: #001c41;
        margin-left: 10px;
      }
    }

    .courseMain {
      display: flex;
      align-items: center;
      justify-content: space-around;

      .courseItem {
        width: 291px;

        .courseImg,
        .courseIcon {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .courseImg {

          .img {
            width: 360px;
            height: 164px;
          }
        }

        .courseIcon {
          margin: 22px 0;
          position: relative;

          .courseYear {
            position: absolute;
            color: #fff;
            z-index: 10;
            font-size: 20px;
          }
        }

        .courseTxt {
          height: 164px;

          .p {
            font-size: 16px;
          }
        }

        .courseActive {
          display: flex;
          align-items: end;
        }
      }
    }
  }

  .brief {
    width: 1200px;
    min-height: 300px;
    margin: 0 auto;
    // display: flex;
    // align-items: center;

    .briefTop {
      display: flex;
      align-items: center;
      margin-bottom: 40px;

      .briefTitle {
        font-size: 24px;
        color: #001c41;
        margin-left: 10px;
      }
    }

    .mapBox {
      // position: relative;
      display: flex;
      align-items: center;
      width: 1200px;
      height: 500px;
      overflow: hidden;

      .mapMain {
        // position: absolute;
        // left: 84px;
        width: 426px;
        height: 500px;
        z-index: 10;
        background: #113f7d;

        .mapInner {

          .mapTop {
            margin-top: 110px;
            position: relative;

            .triangle {
              position: absolute;
              right: -50px;
              width: 0;
              height: 0;
              border-top: 25px solid transparent;
              border-left: 25px solid #2c67af;
              border-right: 25px solid transparent;
              border-bottom: 25px solid transparent;
              opacity: .7;
            }

            .locatTop {
              top: 0;
            }

            .locatDown {
              top: 49px;
            }

            .topItem {
              background: #194b8c;
              font-size: 18px;
              color: #527cb8;
              font-weight: 700;
              text-align: center;
              line-height: 49px;
            }

            .topItemActive {
              background: rgba(45, 103, 176, .7);
              color: #fff;
            }
          }

          .mapDown {
            width: 280px;
            margin: 53px auto 0;
            color: #fff;
            font-size: 16px;

            .downItem {
              display: flex;
              align-items: center;
              margin-bottom: 20px;
              font-size: 16px;
            }
          }

        }
      }
    }
  }

  .join {
    margin: 80px auto 100px;

    .joinBox {
      width: 1200px;
      margin: 0 auto;
      position: relative;

      .img {
        width: 100%;
        height: 100%;
      }

      .joinTitle {
        z-index: 10;
        color: #fff;
        position: absolute;
        top: 0;
        height: 152px;
        margin-left: 100px;
        text-align: center;

        h3 {
          font-size: 20px;
          margin: 40px 0 30px;
        }

        .joinBtn {
          font-size: 16px;
          color: #03052E;
          background: #fff;
          padding: 4px 8px;
          cursor: pointer;
        }
      }
    }
  }
}

.addBox {
  margin-bottom: 100px;

  .imgBox {
    width: 100%;
    // min-width: 1200px;
    height: 610px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .aboutAdd {
      font-size: 64px;
      color: #fff;
      position: absolute;
      z-index: 10;
    }

    .img {
      width: 100%;
      height: 100%;
    }
  }

  .addMain {
    width: 1200px;
    margin: 0 auto;

    .backBox {
      margin-top: 20px;
      display: flex;
      align-items: center;
      cursor: pointer;

      .img {
        height: 20px;
        height: 20px;
      }

      .backTxt {
        font-size: 16px;
        color: #001C41;
      }
    }

    .addTitle {
      font-size: 24px;
      color: #001C41;
      text-align: center;
      padding: 100px 0;
      font-weight: 600;
    }

    .addItem {
      border: 1px solid #bababa;
      border-radius: 6px;
      padding: 20px 40px;
      margin-bottom: 40px;
      position: relative;

      .upIcon,
      .downIcon {
        position: absolute;
        right: 30px;
        font-size: 20px;
        cursor: pointer;
      }

      .upIcon {
        top: 30px;
      }

      .downIcon {
        top: 20px;
      }

      .itemName {
        font-size: 18px;
        color: #000;
        font-weight: 600;
      }

      .itemInner {
        margin-top: 20px;

        .p {
          font-size: 16px;
          margin-bottom: 6px;
        }
      }
    }
  }
}
</style>