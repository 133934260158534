import Vue from 'vue'
import App from './App.vue'
import { Carousel, CarouselItem, Image, Collapse, CollapseItem, Popover, Button, Dialog } from 'element-ui';
import i18n from './i18n'

// 引入router
import VueRouter from 'vue-router'
// 引入路由
import router from './router'

// 淘宝自适应文件
// import './assets/js/flexible.js'

// 引入适配文件
import './util/rem'

Vue.config.productionTip = false

// 引用插件
Vue.use(VueRouter)
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Image);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Popover);
Vue.use(Button);
Vue.use(Dialog);

// Vue.prototype.pathObj = { path:'/home' }

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)
new Vue({
  render: h => h(App),
  router,
  i18n,
  beforeCreate() {
    Vue.prototype.$bus = this // 安装全局事件总线
  }
}).$mount('#app')
