<template>
  <div class="box">
    <div class="videoBox">
      <div class="cutBox" v-on:mouseover="arrowShowFun" v-on:mouseout="arrowHiddenFun">
        <i v-show="arrowShow" @click="arrowClick" class="el-icon-arrow-left"></i>
        <i v-show="arrowShow" @click="arrowClick" class="el-icon-arrow-right"></i>
      </div>
      <!-- <el-carousel height="610px" indicator-position="none" arrow="hover" :autoplay="false">
        <el-carousel-item v-for="item in videoAll" :key="item.id">
          <video width="100%" height="100%" autoplay loop muted :poster="item.img">
            <source :src="item.video" type="video/mp4">
          </video>
        </el-carousel-item>
      </el-carousel> -->
      <video v-show="videoShow === 1" width="100%" autoplay loop muted>
        <source src="https://rfhwcn.oss-cn-beijing.aliyuncs.com/IndexBanner.mp4" type="video/mp4">
      </video>
      <video v-show="videoShow === 2" width="100%" autoplay loop muted>
        <source src="https://rfhwcn.oss-cn-beijing.aliyuncs.com/IndexBanner01.mp4" type="video/mp4">
      </video>
    </div>

    <div class="homeMain">
      <div class="mainItem" id="走进瀚维">
        <el-image class="itemLeft" :src="chart01"></el-image>
        <div class="itemRight">
          <div class="itemTitle">
            <el-image class="iconBlue" :src="iconBlue"></el-image>
            <h2 class="h2"> 走进瀚维 </h2>
          </div>
          <div class="txtBox">
            <p>北京瑞丰瀚维科技发展有限公司成立于2013年12月 ，是一家专业从事数据中心集规划、建设、运营、服务四位一体的创新整合式供应商。</p>
            <p class="txt"> 2014年底，经北京经济技术开发区管委会批准备案，公司与中移铁通有限公司北京分公司达成战略合作，投资建设了“北京铁通IDC-T3数据中心”，并于2015年7月竣工投产。 </p>
            <div class="seeMore" @click="jumpAbout">
              查看更多
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="mainItems" id="我们的优势">
        <div class="benefitTop">
          <div class="itemTitle">
            <el-image class="iconBlue" :src="iconBlue"></el-image>
            <h2 class="h2"> 我们的优势</h2>
          </div>
        </div>

        <div class="benefitMain">
          <div class="benefitLeft" v-on:mouseover="showPopup" v-on:mouseout="hidePopup">
            <div v-show="mask" class="checkMore">
              <div @click="jumpClick" class="checkBtn"> 查看更多 <i class="el-icon-right iconRight"></i> </div>
            </div>
            <div v-if="selectId === '1'" class="benefitGif">
              <el-image class="img" :src="gifVal"></el-image>
            </div>
            <video v-else width="100%" height="100%" autoplay loop :src="videoURL" type="video/mp4">
              <!-- controls
              <source src="https://www.gds-services.com/content/zh_cn/video/banner-video7.mp4" type="video/mp4">
              <source src="movie.ogg" type="video/ogg"> 您的浏览器不支持 video 标签。 -->
            </video>
          </div>
          <div class="benefitRight">
            <!-- <div class="itemSelect" :class="{ activeItem: selectId === item.id }" v-for="item in select" :key="item.id"
              v-on:mouseover="headMouseover(item.id)" @click="headClickJump(item.name, item.path)"> -->
            <div class="itemSelect" :class="{ activeItem: selectId === item.id }" v-for="item in select" :key="item.id"
              v-on:mouseover="headMouseover(item.id)">
              <i class="el-icon-caret-left iconSize" :class="{ activeSize: selectId === item.id }"></i>
              {{ item.title }}
            </div>
          </div>
        </div>
      </div>

      <div class="mainItems" id="我们的服务">
        <div class="benefitTop">
          <div class="itemTitle">
            <el-image class="iconBlue" :src="iconBlue"></el-image>
            <h2 class="h2"> 我们的服务 </h2>
          </div>
        </div>

        <div class="serveBox">
          <div class="serveImgBox" v-for="item in imgList" :key="item.id">
            <div class="nameTip"> {{item.name}} </div>
            <el-image class="serveImg" :src="item.img"></el-image>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var VueScrollTo = require("vue-scrollto");
export default {
  name: "Home",
  data() {
    return {
      videoAll: [
        { video: 'https://rfhwcn.oss-cn-beijing.aliyuncs.com/IndexBanner.mp4', img: require("./images/banner01.png"), id: '01' },
        { video: 'https://rfhwcn.oss-cn-beijing.aliyuncs.com/IndexBanner01.mp4', img: require("./images/banner02.png"), id: '02' }
      ],
      videoURL: 'https://rfhwcn.oss-cn-beijing.aliyuncs.com/Index2.mp4',
      iconBlue: require("./images/iconBlue.png"),
      chart01: require("./images/chart01.jpg"),
      gifVal: require("./images/benefit.gif"),
      select: [
        { title: "优越的地理位置", id: '1', name: '机房基本信息', path: 'datacenter' },
        { title: "可靠的供电系统", id: '2', name: '机房电力情况', path: 'datacenter' },
        { title: "一流的机房环境", id: '3', name: '数据机房情况', path: 'datacenter' },
        { title: "完善的配套设施", id: '4', name: '空调与制冷', path: 'datacenter' },
        { title: "丰富的网络资源", id: '5', name: '网络传输资源', path: 'datacenter' }
      ],
      selectId: '1',
      imgList: [
        { img: require("./images/img01.jpg"), name:'机房运维服务', id: '1' },
        { img: require("./images/img02.jpg"), name:'规划咨讯设计', id: '2' },
        { img: require("./images/img03.jpg"), name:'信息技术服务', id: '3' },
      ],
      mask: false,
      arrowShow: false,
      videoShow: 1,
    };
  },

  methods: {
    // 跳转我们的优势
    headClickJump(val, path) {
      let pathId = "/" + path
      this.$router.push(pathId);
      this.$bus.$emit('setRoute', pathId)
      setTimeout(() => {
        VueScrollTo.scrollTo(document.getElementById(val), 1000, {
          offset: -100,
        });
      }, 100)
    },

    // 鼠标移入触发
    headMouseover(val) {
      let url = 'https://rfhwcn.oss-cn-beijing.aliyuncs.com/Index'
      this.selectId = val
      if (val > 1) {
        this.videoURL = url + val + '.mp4'
      }
    },

    // 查看更多
    jumpAbout() {
      let val = '企业介绍'
      this.$router.push("/about");
      this.$bus.$emit('setRoute', '/about')
      setTimeout(() => {
        VueScrollTo.scrollTo(document.getElementById(val), 1000, {
          offset: -60,
        });
      }, 100)
    },

    // 视频区域移入
    showPopup() {
      this.mask = true
    },

    // 视频区域移出
    hidePopup() {
      this.mask = false
    },

    // 点击遮罩跳转
    jumpClick() {
      let arr = this.select
      let name = '', path = ''
      arr.map((item) => {
        if (item.id === this.selectId) {
          name = item.name
          path = item.path
        }
      })
      this.headClickJump(name, path)
    },

    // banner  视频区域移入
    arrowShowFun() {
      this.arrowShow = true
    },

    // banner  视频区域移入
    arrowHiddenFun() {
      this.arrowShow = false
    },
    arrowClick(){
      this.videoShow === 1 ? this.videoShow = 2 : this.videoShow = 1
    }
  },
};
</script>

<style scoped lang='less'>
/deep/ .el-carousel__button {
  // 指⽰器按钮
  background-color: rgba(0, 0, 0, 0.2);
}

/deep/ .is-active .el-carousel__button {
  // 指⽰器激活按钮
  background: blue;
}

/deep/ .el-carousel__item {
  height: auto;
  overflow: hidden;
}

/deep/ .el-carousel--horizontal {
  overflow: hidden;
}

.box {
  width: 100%;
  // height: 700px;

  .videoBox {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    // justify-content: space-around;

    .cutBox {
      width: 100%;
      height: 120px;
      // background: #f00;
      position: absolute;
      display: flex;
      align-items: center;
      color: #fff;
      font-size: 40px;
      justify-content: space-between;
      z-index: 1000;
    }
  }

  .img {
    width: 100%;
    height: 100%;
  }

  .homeMain {
    width: 1200px;
    margin: 80px auto;
    // background: skyblue;

    .mainItem {
      display: flex;
      align-items: center;
      margin-bottom: 80px;

      .itemLeft {
        width: 680px;
        height: 360px;
      }

      .itemRight {
        margin-left: 62px;
        width: 454px;

        .itemTitle {
          display: flex;
          align-items: center;

          .h2 {
            margin-left: 16px;
            font-size: 24px;
            color: #000;
          }
        }

        .txtBox {
          font-size: 16px;
          color: #4d4d4d;
          margin-top: 40px;
          line-height: 30px;

          .txt {
            margin-top: 10px;
          }

          .seeMore {
            margin-top: 40px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            cursor: pointer;
          }
        }
      }
    }

    .mainItems {
      margin-bottom: 80px;

      .benefitTop {
        .itemTitle {
          display: flex;
          align-items: center;

          .h2 {
            margin-left: 16px;
            font-size: 24px;
            color: #000;
          }
        }
      }

      .benefitMain {
        display: flex;
        margin-top: 40px;
        background: #001C41;
        overflow: hidden;

        .benefitLeft {
          width: 890px;
          height: 500px;
          position: relative;

          .checkMore {
            z-index: 100;
            color: #fff;
            font-size: 30px;
            width: 890px;
            height: 500px;
            position: absolute;
            background: rgba(0, 0, 0, .5);
            display: flex;
            align-items: center;
            justify-content: space-around;

            .checkBtn {
              border: 1px solid #fff;
              padding: 10px 30px;
              border-radius: 50px;

              .iconRight {
                border: 1px solid #fff;
                border-radius: 50%;
                padding: 10px;
              }
            }
          }

          .benefitGif {
            width: 890px;
            height: 500px;

            .img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .benefitRight {
          width: 311px;

          .itemSelect {
            height: 99px;
            width: 311px;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: #001C41;
            cursor: pointer;
            color: #4D617A;
            border-bottom: 1px solid #2C67AF;

            /deep/ .iconSize {
              font-size: 30px;
              color: #4D617A;
            }

            /deep/ .activeSize {
              font-size: 30px;
              color: #FFFFFF;
            }
          }

          .activeItem {
            color: #fff;
            font-size: 18px;
            background: #2C67AF;
          }
        }
      }

      .serveBox {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .serveImgBox {
          position: relative;
          .nameTip{
            position: absolute;
            bottom: 20px;
            left: 20px;
            z-index: 10;
            color: #fff;
            font-size: 18px;
          }

          .serveImg {
            width: 388px;
            height: 242px;
          }
        }
      }
    }
  }
}
</style>