<template>
  <div>
    <div class="contain">
      <div class="imgBox">
        <!-- <div class="imgTitle">产业服务</div> -->
        <el-image class="img" :src="img"></el-image>
      </div>
      <div class="main">
        <div class="itemSmall" id="机房运维服务">
          <div class="smallTitle">
            <el-image class="img" :src="iconImg"></el-image>
            <div class="title"> 机房运维服务 </div>
          </div>
          <div class="smallBox">
            <div class="smallIcon">
              <div class="smallContent" v-for="item in iconArr" :key="item.id">
                <el-image class="img" :src="item.img"></el-image>
                <div class="smallTime">{{ item.time }}</div>
                <div class="smallTtxt">{{ item.text }}</div>
                <div class="smallTtxt" v-if="item.texts">{{ item.texts }}</div>
              </div>
            </div>
          </div>
          <div class="smallCarousel">
            <div class="leftBtn" @click="arrowClick('left')">
              <i class="el-icon-arrow-left"></i>
            </div>
            <el-carousel :interval="10000" trigger="click" height="538px" arrow="never" indicator-position="none"
              ref="cardShow">
              <!-- <el-carousel trigger="click" arrow="never" indicator-position="none" ref="cardShow"> -->
              <el-carousel-item v-for="item in all" :key="item.id">
                <div class="carousel">
                  <div class="carouselImg" v-for="items in item.carousel" :key="items.id">
                    <el-image class="img" :src="items.img"></el-image>
                    <!-- <div class="carouselTxt">{{items.text}}</div> -->
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
            <div class="rightBtn" @click="arrowClick('right')">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>

        <div class="largeBox" id="规划咨询设计">
          <div class="smallTitle">
            <el-image class="img" :src="iconImg"></el-image>
            <div class="title"> 规划咨询设计 </div>
          </div>
          <div class="itemLarge">
            <!-- swiper start -->
            <div class="swiper-content">
              <swiper ref="mySwiper" :options="swiperOptions" class="show-swiper">
                <swiper-slide v-for="(item, index) in planArr" :key="index">
                  <div class="swiper-item">
                    <img style="width:100%;height:100%" :src="item.img" />
                  </div>
                </swiper-slide>
              </swiper>
            </div>
            <!-- swiper end  -->
          </div>
        </div>

        <div class="itemSmall" id="信息技术服务">
          <div class="smallTitle">
            <el-image class="img" :src="iconImg"></el-image>
            <div class="title"> 信息技术服务 </div>
          </div>
          <div class="smallBox">
            <div class="smallIcon">
              <div class="smallContent" v-for="item in skillArr" :key="item.id">
                <el-image class="imgSmall" :src="item.img"></el-image>
                <div class="smallTime">{{ item.time }}</div>
                <div class="smallTtxt">{{ item.text }}</div>
                <div class="smallTtxt" v-if="item.texts">{{ item.texts }}</div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'Service',
  data() {
    return {
      visible: false,
      img: require("./images/aboutBanner.jpg"),
      iconImg: require("./images/iconBlue.png"),
      activeName: '1',
      iconArr: [
        { img: require("./images/icon1.png"), id: '01', time: '7*24小时', text: '全天候远程电话', texts: '或机房现场技术支持' },
        { img: require("./images/icon2.png"), id: '02', time: '7*24小时', text: '专业技术人员维护' },
        { img: require("./images/icon3.png"), id: '03', time: '7*24小时', text: '管家级快速', texts: '响应客户服务' },
      ],
      all: [
        {
          carousel: [
            { img: require("./images/carousels01.png"), id: '01', text: '机房运维-网络维护（一）' },
            { img: require("./images/carousels02.png"), id: '02', text: '机房运维-网络维护（二）' },
            { img: require("./images/carousels03.png"), id: '03', text: '机房运维-网管中心' },
            { img: require("./images/carousels04.png"), id: '04', text: '机房运维-供配电系统' },
            { img: require("./images/carousels05.png"), id: '05', text: '机房运维-设备维护' },
            { img: require("./images/carousels06.png"), id: '06', text: '机房运维-消防气灭' },
          ],
          id: '004'
        },
        {
          carousel: [
            { img: require("./images/carousel01.png"), id: '01', text: '机房运维-不间断电源系统（一）' },
            { img: require("./images/carousel02.png"), id: '02', text: '机房运维-不间断电源系统（二）' },
            { img: require("./images/carousel03.png"), id: '03', text: '机房运维-供配电系统' },
            { img: require("./images/carousel04.png"), id: '04', text: '机房运维-空调加湿器' },
            { img: require("./images/carousel05.png"), id: '05', text: '机房运维-空调外机' },
            { img: require("./images/carousel06.png"), id: '06', text: '机房运维-视频监控系统' },
          ],
          id: '001'
        },
        {
          carousel: [
            { img: require("./images/carousels01.png"), id: '01', text: '机房运维-网络维护（一）' },
            { img: require("./images/carousels02.png"), id: '02', text: '机房运维-网络维护（二）' },
            { img: require("./images/carousels03.png"), id: '03', text: '机房运维-网管中心' },
            { img: require("./images/carousels04.png"), id: '04', text: '机房运维-供配电系统' },
            { img: require("./images/carousels05.png"), id: '05', text: '机房运维-设备维护' },
            { img: require("./images/carousels06.png"), id: '06', text: '机房运维-消防气灭' },
          ],
          id: '002'
        }, {
          carousel: [
            { img: require("./images/carousel01.png"), id: '01', text: '机房运维-不间断电源系统（一）' },
            { img: require("./images/carousel02.png"), id: '02', text: '机房运维-不间断电源系统（二）' },
            { img: require("./images/carousel03.png"), id: '03', text: '机房运维-供配电系统' },
            { img: require("./images/carousel04.png"), id: '04', text: '机房运维-空调加湿器' },
            { img: require("./images/carousel05.png"), id: '05', text: '机房运维-空调外机' },
            { img: require("./images/carousel06.png"), id: '06', text: '机房运维-视频监控系统' },
          ],
          id: '003'
        }
      ],
      planArr: [
        { img: require("./images/plan01.png"), id: '01', text: '需求分析' },
        { img: require("./images/plan02.png"), id: '02', text: '等级规划' },
        { img: require("./images/plan03.png"), id: '03', text: '节能专项咨询' },
        { img: require("./images/plan04.png"), id: '04', text: '绿色改造咨询' },
        { img: require("./images/plan05.png"), id: '05', text: '机房改造设计' },
        { img: require("./images/plan06.png"), id: '06', text: '机房选址' },
        { img: require("./images/plan07.png"), id: '07', text: '立项规划' },
      ],
      skillArr: [
        { img: require("./images/skill01.png"), id: '01', time: '软件服务', text: '软件开发服务、软件维护服务、软件测试服务' },
        { img: require("./images/skill02.png"), id: '02', time: '信息增值服务', text: '数据存储、数据库管理、电子商务平台' },
        { img: require("./images/skill03.png"), id: '03', time: '信息系统服务', text: '信息系统应用、数据中心、网站内容维护' },
      ],
      screenWidth: document.documentElement.clientWidth,//屏幕宽度

      //#region 
      // swiper start
      slides: [1, 2, 3, 4, 5, 6, 7],

      swiperOptions: {
        slidesPerView: 'auto',
        watchSlidesProgress: true,
        // 设定slide与左边框的预设偏移量（单位px）
        //slidesOffsetBefore: 37,
        // 设置slide之间的距离（单位px）
        spaceBetween: 17,
        centeredSlides: true,
        init: false,
        loop: true,
        // loopAdditionalSlides: 7,
        speed: 1000,
        // autoplay: {
        //   delay: 2000,
        //   disableOnInteraction: true,
        // },      
        on: {
          progress: function () {
            for (let i = 0; i < this.slides.length; i++) {
              const slide = this.slides.eq(i)
              const slideProgress = this.slides[i].progress
              const scale = 1 - Math.abs(slideProgress) / 3 // 缩放权重值，随着progress由中向两边依次递减，可自行调整
              slide.transform(`scale3d(${scale}, ${scale}, 1)`)
            }
          },
          slideChange: function () {
            console.log(this.activeIndex)
            //store.commit('SET_ACTIVE_INDEX', this.activeIndex)
          }
        }
      }

      // swiper end 
      //#endregion


    }
  },
  components: {
    swiper,
    swiperSlide
  },

  // <!--在watch中监听实时宽高-->
  watch: {
    screenWidth: function (val, oldVal) {
      console.log('width', val, oldVal)
      // if (val != oldVal) {
      //     this.initSwiper()
      // }
    }
  },

  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper
    },
  },

  mounted() {
    this.initSwiper()
    let that = this
    window.addEventListener('resize', function () {
      that.screenWidth = document.body.offsetWidth;
    })
  },

  methods: {

    initSwiper() {
      this.$nextTick(async () => {
        await this.swiper.init() // 现在才初始化
        // await this.swiper.slideTo(this.activeItemIndex)
        await this.swiper.slideTo(1)
      })
    },
    arrowClick(val) {
      if (val === 'right') {
        this.$refs.cardShow.next()
      } else {
        this.$refs.cardShow.prev()
      }

    }
  }
}
</script>

<style lang='less' scoped>
// swiper :start
.swiper-content {
  width: 100%;
  height: 480px;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  padding: 50px 0;

  .show-swiper {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    .swiper-slide {
      width: 800px;
      // 表示所有属性都有动作效果，过度时间为0.4s，以慢速开始和结束的过渡效果
      // transition: all .4s cubic-bezier(.4, 0, .2, 1);

      .swiper-item {
        width: 100%;
        height: 480px;
        background: rgb(140, 172, 134);
        border-radius: 6px;
        color: orangered;
        font-size: 24px;
        line-height: 1.5;
        // border: 1px solid orangered;
      }
    }
  }
}

// swiper end 
.imgBox {
  width: 100%;
  // min-width: 1200px;
  height: 610px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .imgTitle {
    position: absolute;
    color: #fff;
    font-size: 65px;
    z-index: 10;
  }

  .img {
    width: 100%;
    height: 100%;
  }
}

.main {

  .itemSmall {
    width: 1200px;
    margin: 80px auto 80px;

    .smallTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #000;
      margin-bottom: 54px;

      .title {
        margin-left: 10px;
      }
    }

    .smallBox {
      .smallIcon {
        display: flex;
        align-items: center;
        justify-content: space-around;

        .smallContent {
          .smallTime {
            text-align: center;
            font-size: 20px;
            color: #010101;
            margin: 20px 0 16px;
          }

          .smallTtxt {
            text-align: center;
            font-size: 16px;
            color: #000;
          }

          .img {
            width: 124px;
            height: 124px;
          }

          .imgSmall {
            width: 360px;
            height: 203px;
          }
        }
      }
    }

    .smallCarousel {
      // width: 1200px;
      height: 538px;
      position: relative;
      margin: 40px auto 0;

      .leftBtn,
      .rightBtn {
        font-size: 40px;
        color: #999;
        position: absolute;
        top: 244px;
        cursor: pointer;
      }

      .leftBtn {
        left: -100px;
      }

      .rightBtn {
        right: -100px;
      }

      .carousel {
        height: 538px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .carouselImg {
          width: 389px;
          height: 264px;
          position: relative;
          margin-bottom: 10px;

          .img {
            width: 100%;
            height: 100%;
          }

          .carouselTxt {
            position: absolute;
            left: 0;
            bottom: 5px;
            background: #2c67af;
            height: 34px;
            line-height: 34px;
            width: 90%;
            padding-left: 10%;
            font-size: 16px;
            color: #fff;
            opacity: .8;
          }
        }
      }
    }
  }

  .largeBox {

    // min-width: 1200px;
    .smallTitle {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #000;
      margin-bottom: 54px;

      .title {
        margin-left: 10px;
      }
    }

    .itemLarge {
      width: 100%;
      // padding: 80px 0;
      // height: 740px;
      background: #113F7D;
      height: 580px;

      .largeMain {
        width: 90%;
        margin: 0 5%;
        padding-top: 4%;
      }
    }
  }
}

/deep/.is-animating {
  height: 538px;
}

/deep/.el-carousel {
  height: 538px;
  overflow: hidden;
}

.el-carousel__item.is-animating {
  transition: transform 4s ease-in-out, -webkit-transform 4s ease-in-out;
}
</style>